import pt from './pt.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import de from './de.json';

const messages = {
    pt: {
        translations: pt // Portuguese
    },
    en: {
        translations: en // English
    },
    es: {
        translations: es // Spanish
    },
    fr: {
        translations: fr // French
    },
    it: {
        translations: it // Italian
    },
    de: {
        translations: de // German
    },
}
export { messages }