import React from 'react';

export default function ProductSpecifications() {
    return <section>
        <h2>Product Specifications</h2>
        <p>This product includes a variety of components and pages that can be used to build a complete application:</p>

        <h3>Base UI Components</h3>
        <ul>
        <li><strong>Button</strong>: Interactive button for actions.</li>
        <li><strong>ButtonGroup</strong>: Grouping of buttons.</li>
        <li><strong>Checkbox</strong>: Checkboxes for selection.</li>
        <li><strong>Comment</strong>: Component for displaying comments.</li>
        <li><strong>Dropdown</strong>: Dropdown menu.</li>
        <li><strong>InlineEdit</strong>: Inline text editing.</li>
        <li><strong>Input</strong>: Text input field.</li>
        <li><strong>Label</strong>: Label for form components.</li>
        <li><strong>LineItem</strong>: Line item.</li>
        <li><strong>ListGroup</strong>: List group.</li>
        <li><strong>Lozenge</strong>: Visual tag.</li>
        <li><strong>Modal</strong>: Modal window.</li>
        <li><strong>Pagination</strong>: Pagination for lists.</li>
        <li><strong>Progress</strong>: Progress bar.</li>
        <li><strong>Radio</strong>: Radio button.</li>
        <li><strong>Switch</strong>: Toggle button.</li>
        <li><strong>Table</strong>: Data table.</li>
        <li><strong>Tabs</strong>: Navigation tabs.</li>
        <li><strong>Tooltip</strong>: Tooltip.</li>
        </ul>

        <h3>Views and Pages</h3>
        <ul>
        <li><strong>E-commerce</strong>:
            <ul>
            <li>Product Listing</li>
            <li>Product Detail</li>
            <li>Cart</li>
            <li>Checkout</li>
            <li>Orders</li>
            </ul>
        </li>
        <li><strong>Email</strong>:
            <ul>
            <li>Inbox</li>
            <li>Compose</li>
            <li>Read Email</li>
            </ul>
        </li>
        <li><strong>File Manager</strong></li>
        <li><strong>Kanban Boards</strong></li>
        <li><strong>Error Pages</strong>: 404, 500</li>
        <li><strong>Profile</strong></li>
        <li><strong>Projects</strong>:
            <ul>
            <li>Project Overview</li>
            <li>Project List</li>
            <li>Create Project</li>
            </ul>
        </li>
        <li><strong>Starter Page</strong></li>
        </ul>
    </section>
}